@import "/src/assets/scss/helpers/spacing.helper";

.c-icons {
    width: toRem(24);
    height: toRem(24);
    background-size: cover;
}
 
.c-logo {
    background-image: url("../../images/logo.svg");
}
 
.c-welcome-logo {
    background-image: url("../../images/welcome-logo.svg");
}